import * as THREE from "three";

export class TextureCache {
	public static instance: TextureCache = new TextureCache();
	private loader: THREE.TextureLoader = new THREE.TextureLoader();

	public load(url: string): THREE.Texture {
		return this.loader.load(url);
	}
}
