export class EventEmitter<T = void> {
	public callbacks: any[] = [];

	public addListener(callback: (data: T) => void) {
		this.callbacks.push(callback);
	}

	public emit(data: T) {
		for (const cb of this.callbacks) {
			cb(data);
		}
	}

	public removeListener(callback: (data: T) => void) {
		if (this.callbacks.indexOf(callback) >= 0) {
			this.callbacks.splice(this.callbacks.indexOf(callback), 1);
		}
	}

	public removeAllListeners(){
		this.callbacks = [];
	}
}
