import * as THREE from "three";
import { MapPlaneNode } from "geo-three";

//Create custom map node that overrides material and disables depth write, so that pointcloud is always shown above map
export class CustomMapPlaneNode extends MapPlaneNode {
	private static transparentTexture: THREE.Texture;

	public constructor(parentNode, mapView, location, level, x, y) {
		super(parentNode, mapView, location, level, x, y);
		(this as any).material.color.a = 0;
		(this as any).material.depthWrite = false;
		(this as any).material.transparent = false;
		(this as any).material.alphaTest = 1;
		
		if(!CustomMapPlaneNode.transparentTexture){
			CustomMapPlaneNode.transparentTexture = this.createTransparentTexture();
		}
	}

	private createTransparentTexture(){
		const transparentImage = document.createElement("canvas");
		transparentImage.width = 1;
		transparentImage.height = 1;
		transparentImage.getContext("2d").clearRect(0, 0, 1, 1);
		return new THREE.Texture(transparentImage);
	}

	public subdivide(this:any){
		// console.log("Subdivide:",this.level,this.x,this.y);
		super.subdivide();
	}

	public simplify(this:any): void
	{
		this.destroyChildren();
		super.simplify();
		// console.log("Simplify:",this.level,this.x,this.y);
	}

	public async loadTexture(): Promise<void>
	{
		try 
		{
			// if((this as any).material && (this as any).material.map){
			// 	console.log("Texture already loaded");
			// 	return;
			// }
			const self = this as any;
			// console.log("loadTexture:",self.level,self.x,self.y);
			(this as any).material.map = CustomMapPlaneNode.transparentTexture;
			const image: HTMLImageElement = await (this as any).mapView.provider.fetchTile(self.level, self.x, self.y);
			if(!(this as any).material){
				return;
			}

			const texture = new THREE.Texture(image);
			texture.generateMipmaps = false;
			texture.format = THREE.RGBAFormat;
			texture.magFilter = THREE.LinearFilter;
			texture.minFilter = THREE.LinearFilter;
			texture.needsUpdate = true;
			
			(this as any).material.map = texture;
			(this as any).nodeReady();
		}
		catch (e) 
		{
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			context.fillStyle = "#FF0000";
			context.fillRect(0, 0, 1, 1);

			const texture = new THREE.Texture(canvas as any);
			texture.generateMipmaps = false;
			texture.needsUpdate = true;

			(this as any).material.map = texture;
			(this as any).nodeReady();
		}
	}

	unloadTexture(this: any){
		if(this.material && this.material.map){
			this.material.map.dispose();
			this.material.map = null;
		}
	}

	public destroyChildren(){
		const children = (this as any).children as CustomMapPlaneNode[];
		for(const child of children){
			if(child.dispose){
				child.dispose();
			}
		}
	}

	public dispose(){
		this.destroyChildren();
		if((this as any).material){
			this.unloadTexture();
			(this as any).material.dispose();
			(this as any).material = null;
		}		
	}
}
