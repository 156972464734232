import { MapView, MapNode, MapProvider, OpenStreetMapsProvider } from "geo-three";
import { BufferGeometry, Camera, Group, Material, Scene, WebGLRenderer } from "three";

export class CustomMapView extends MapView {

	public constructor(root: (number | MapNode) = MapView.PLANAR, provider: MapProvider = new OpenStreetMapsProvider(), heightProvider: MapProvider = null) 
	{
		super(root, provider, heightProvider);
	}

	public onBeforeRender: (renderer: WebGLRenderer, scene: Scene, camera: Camera, geometry: BufferGeometry, material: Material, group: Group) => void = () => 
	{
		//Empty method to override behaviour from parent that updates lod
		//Lod updates in onBeforeRender() causes white tile flickering
	};

	update(camera: Camera, renderer: WebGLRenderer, scene: Scene){
		//This method needs to be called manually. In order to update lod and prevent flickering
		(this as any).lod.updateLOD(this, camera, renderer, scene);
	}

	dispose(this: MapView){
		this.root.dispose();
	}
}
