import * as THREE from "three";
import { ElementType } from "../data-structures/element-type";
import { MeasurementType as MeasurementType } from "../data-structures/measure-type";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { Utils } from "../utils/utils";
import { Mesh, MeshBasicMaterial, Triangle, Vector3 } from "three";
import { radToDeg } from "three/src/math/MathUtils";

declare const Potree: any;
declare const proj4: any;
const TextSprite = Potree.TextSprite;
const tempVector = new THREE.Vector3();

function createAreaLabel(){
	const areaLabel = new TextSprite("");

	areaLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
	areaLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	areaLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	areaLabel.fontsize = 16;
	areaLabel.material.depthTest = false;
	areaLabel.material.opacity = 1;
	areaLabel.visible = false;
	
	return areaLabel;
}

function createCircleRadiusLabel(){
	const circleRadiusLabel = new TextSprite("");

	circleRadiusLabel.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
	circleRadiusLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
	circleRadiusLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
	circleRadiusLabel.fontsize = 16;
	circleRadiusLabel.material.depthTest = false;
	circleRadiusLabel.material.opacity = 1;
	circleRadiusLabel.visible = false;
	
	return circleRadiusLabel;
}

function createCircleRadiusLine(){
	const lineGeometry = new LineGeometry();

	lineGeometry.setPositions([
		0, 0, 0,
		0, 0, 0,
	]);

	const lineMaterial = new LineMaterial({ 
		color: 0xff0000, 
		linewidth: 2, 
		resolution:  new THREE.Vector2(1000, 1000),
		gapSize: 1,
		dashed: true,
	});

	lineMaterial.depthTest = false;

	const circleRadiusLine = new Line2(lineGeometry, lineMaterial);
	circleRadiusLine.visible = false;

	return circleRadiusLine;
}

function createCircleLine(){
	const coordinates = [];

	const n = 128;
	for(let i = 0; i <= n; i++){
		const u0 = 2 * Math.PI * (i / n);
		const u1 = 2 * Math.PI * (i + 1) / n;

		const p0 = new THREE.Vector3(
			Math.cos(u0), 
			Math.sin(u0), 
			0
		);

		const p1 = new THREE.Vector3(
			Math.cos(u1), 
			Math.sin(u1), 
			0
		);

		coordinates.push(
			...p0.toArray(),
			...p1.toArray(),
		);
	}

	const geometry = new LineGeometry();
	geometry.setPositions(coordinates);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		dashSize: 5, 
		gapSize: 2,
		linewidth: 2, 
		resolution:  new THREE.Vector2(1000, 1000),
	});

	material.depthTest = false;

	const circleLine = new Line2(geometry, material);
	circleLine.visible = false;
	circleLine.computeLineDistances();

	return circleLine;
}

function createCircleCenter(){
	const sg = new THREE.SphereGeometry(1, 32, 32);
	const sm = new THREE.MeshNormalMaterial();
	
	const circleCenter = new THREE.Mesh(sg, sm);
	circleCenter.visible = false;

	return circleCenter;
}

function createLine(){
	const geometry = new LineGeometry();

	geometry.setPositions([
		0, 0, 0,
		0, 0, 0,
	]);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		linewidth: 2, 
		resolution:  new THREE.Vector2(1000, 1000),
		gapSize: 1,
		dashed: true,
	});

	material.depthTest = false;

	const line = new Line2(geometry, material);

	return line;
}

function createCircle(){

	const coordinates = [];

	const n = 128;
	for(let i = 0; i <= n; i++){
		const u0 = 2 * Math.PI * (i / n);
		const u1 = 2 * Math.PI * (i + 1) / n;

		const p0 = new THREE.Vector3(
			Math.cos(u0), 
			Math.sin(u0), 
			0
		);

		const p1 = new THREE.Vector3(
			Math.cos(u1), 
			Math.sin(u1), 
			0
		);

		coordinates.push(
			...p0.toArray(),
			...p1.toArray(),
		);
	}

	const geometry = new LineGeometry();
	geometry.setPositions(coordinates);

	const material = new LineMaterial({ 
		color: 0xff0000, 
		dashSize: 5, 
		gapSize: 2,
		linewidth: 2, 
		resolution:  new THREE.Vector2(1000, 1000),
	});

	material.depthTest = false;

	const line = new Line2(geometry, material);
	line.computeLineDistances();

	return line;

}

function createAzimuth(){

	const azimuth = {
		label: null,
		center: null,
		target: null,
		north: null,
		centerToNorth: null,
		centerToTarget: null,
		centerToTargetground: null,
		targetgroundToTarget: null,
		circle: null,

		node: null,
	};

	const sg = new THREE.SphereGeometry(1, 32, 32);
	const sm = new THREE.MeshNormalMaterial();

	{
		const label = new TextSprite("");

		label.setTextColor({r: 140, g: 250, b: 140, a: 1.0});
		label.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
		label.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
		label.fontsize = 16;
		label.material.depthTest = false;
		label.material.opacity = 1;

		azimuth.label = label;
	}

	azimuth.center = new THREE.Mesh(sg, sm);
	azimuth.target = new THREE.Mesh(sg, sm);
	azimuth.north = new THREE.Mesh(sg, sm);
	azimuth.centerToNorth = createLine();
	azimuth.centerToTarget = createLine();
	azimuth.centerToTargetground = createLine();
	azimuth.targetgroundToTarget = createLine();
	azimuth.circle = createCircle();

	azimuth.node = new THREE.Object3D();
	azimuth.node.add(
		azimuth.centerToNorth,
		azimuth.centerToTarget,
		azimuth.centerToTargetground,
		azimuth.targetgroundToTarget,
		azimuth.circle,
		azimuth.label,
		azimuth.center,
		azimuth.target,
		azimuth.north,
	);

	return azimuth;
}

export class Measure extends THREE.Object3D {
	points = [];
	_showDistances = true;
	_showCoordinates = false;
	_showArea = false;
	_closed = true;
	_showAngles = false;
	_showCircle = false;
	_showHeight = false;
	_showEdges = true;
	_showAzimuth = false;
	_hover = false;
	_selected = false;
	_measurementTypes: MeasurementType[] = [];
	layerName?: string;
	maxMarkers = Number.MAX_SAFE_INTEGER;
	unit?: string = "";
	hasMapCoordinates = false;
	sphereGeometry = new THREE.SphereGeometry(0.7, 10, 10);
	color = new THREE.Color(0xff0000);
	areaMesh: Mesh;
	areaHash?: string;
	areaMesh3dMeasurement = 0;
	mapCoordinateSystem = "EPSG:3857";
	projectCoordinateSystem?: string;
	spheres = [];
	edges = [];
	sphereLabels = [];
	edgeLabels = [];
	coordinateLabels = [];
	selectedSphere?: any;

	areaLabel = createAreaLabel();
	circleRadiusLabel = createCircleRadiusLabel();
	circleRadiusLine = createCircleRadiusLine();
	circleLine = createCircleLine();
	circleCenter = createCircleCenter();

	azimuth = createAzimuth();

	constructor () {
		super();

		(this.constructor as any).counter = ((this.constructor as any).counter === undefined) ? 0 : (this.constructor as any).counter + 1;

		this.name = "Measure_" + (this.constructor as any).counter;

		const geometry = new THREE.BufferGeometry();
		const material = new THREE.MeshBasicMaterial({
			color: this.color,
			opacity: 0.2,
			transparent: true,
			depthTest: false,
			depthWrite: false,
			side: THREE.DoubleSide
		});
		this.areaMesh = new THREE.Mesh( geometry, material );
		this.areaMesh.name = "area";
		this.add(this.areaMesh);
		this.add(this.areaLabel);
		this.add(this.circleRadiusLabel);
		this.add(this.circleRadiusLine);
		this.add(this.circleLine);
		this.add(this.circleCenter);

		this.add(this.azimuth.node);
	}

	get elementType(): ElementType {
		if (this.showCircle) {
			return "circle";
		}
		return this.points.length == 1 ? "point" : "line";
	}

	get displayedMeasurements(): MeasurementType[] {
		return this._measurementTypes ?? [];
	}

	set displayedMeasurements(value: MeasurementType[]) {
		this._measurementTypes = value;

		const showDistances = this._measurementTypes.includes(MeasurementType.Distance3D) || this._measurementTypes.includes(MeasurementType.Distance2D);
		console.log("Setting new measure type: ", value, "Distances:", showDistances);
		this.showDistances = showDistances;
		this.showHeight = this._measurementTypes.includes(MeasurementType.Height);
		this.showAngles = this._measurementTypes.includes(MeasurementType.Angle);
		this.showArea = this._measurementTypes.includes(MeasurementType.Area2D) || this._measurementTypes.includes(MeasurementType.Area3D);
		this.closed = this.showArea;

		this.updateAreaMesh();
	}

	get hover(): boolean {
		return this._hover == true;
	}

	set hover(value: boolean) {
		this._hover = value;

		const lineEdges = this.edges;
		const lineWidth = this._hover ? 3 : 2;

		if (this.points.length > 1) {
			for (const edge of lineEdges) {
				edge.material.linewidth = lineWidth;
			}
		}

		if(this.maxMarkers == 1 && this.selectedSphere){
			this.showCoordinates = this._hover;
		}

		if (this.circleRadiusLine) {
			this.circleLine.material.linewidth = lineWidth;
			this.circleRadiusLine.material.linewidth = lineWidth;
		}
	}

	get selected(): boolean {
		return this._selected == true;
	}

	set selected(value: boolean) {
		this._selected = value;

		const lineWidth = this._hover ? 4 : 2;

		//Make line thicker and show editing spheres (only for lines)
		if(this.maxMarkers == 1 && this.selectedSphere){
			this.selectedSphere.visible = this._selected;
		}else if (this.points.length > 1) {
			const lineEdges = this.edges;
			for (const edge of lineEdges) {
				edge.material.linewidth = lineWidth;
			}

			for (const sphere of this.spheres) {
				sphere.visible = this._selected;
			}
		}

		if (this.circleRadiusLine) {
			this.circleLine.material.linewidth = lineWidth;
			this.circleRadiusLine.material.linewidth = lineWidth;
		}
	}

	updateAreaMesh(){
		let hash = this.points.reduceRight((prev,current)=>prev+current.position.x+current.position.y+current.position.z,0);
		hash += this.showArea ? 1 : 0;
		hash += this.color.getHex();

		if(this.areaHash == hash){
			//Stop if points are the same as previously
			return;
		}
		this.areaHash = null;

		if(this.showArea && this.points.length >= 3){
			const vertices = this.points.map((e)=>e.position);
			const geometry = this.areaMesh.geometry;
			geometry.clearGroups();
			(this.areaMesh.material as MeshBasicMaterial).color = this.color;

			const triangles = THREE.ShapeUtils.triangulateShape( vertices, [] );

			const positions:number[] = [];
			for(let i = 0; i < vertices.length;i++){
				positions.push(vertices[i].x);
				positions.push(vertices[i].y);
				positions.push(vertices[i].z);
			}

			const indeces:number[] = [];
			const t = new Triangle();
			let totalArea = 0;
			const outVec = new Vector3();
			for(let i = 0; i < triangles.length;i++){
				const triangle = triangles[i];
				indeces.push(triangle[0]);
				indeces.push(triangle[1]);
				indeces.push(triangle[2]);

				let point = this.getPointInProjectCoordinateSystem(this.points[triangle[0]].position,outVec);
				t.a.x = point.x;
				t.a.y = point.y;
				t.a.z = point.z;

				point = this.getPointInProjectCoordinateSystem(this.points[triangle[1]].position,outVec);
				t.b.x = point.x;
				t.b.y = point.y;
				t.b.z = point.z;

				point = this.getPointInProjectCoordinateSystem(this.points[triangle[2]].position,outVec);
				t.c.x = point.x;
				t.c.y = point.y;
				t.c.z = point.z;

				totalArea += t.getArea();
			}
			this.areaMesh3dMeasurement = totalArea;

			geometry.setIndex(indeces);
			geometry.setAttribute( "position", new THREE.Float32BufferAttribute( positions, 3 ) );
			geometry.computeBoundingSphere()

			this.areaHash = hash;
			this.areaMesh.visible = true;
		}else{
			this.areaMesh.visible = false;
		}
	}

	private getPointInProjectCoordinateSystem(point: Vector3, outVector: Vector3) : Vector3{
		if(this.hasMapCoordinates && this.projectCoordinateSystem){
			const converted = proj4(this.mapCoordinateSystem, this.projectCoordinateSystem).forward([point.x, point.y, point.z]);
			outVector.x = converted[0];
			outVector.y = converted[1];
			outVector.z = converted[2];
		}else{
			outVector.x = point.x;
			outVector.y = point.y;
			outVector.z = point.z;
		}
		return outVector;
	}

	public getPointInTargetCoordinateSystem(index: number): Vector3{
		const v = new Vector3();
		if(index >= 0 && index < this.points.length ){	
			this.getPointInProjectCoordinateSystem(this.points[index].position,v);
		}
		return v;
	}

	getRadius (): number {
		if (this.showCircle && this.points.length === 3) {
			const A = new Vector3();
			const B = new Vector3();
			const C = new Vector3();
			this.getPointInProjectCoordinateSystem(this.points[0].position,A);
			this.getPointInProjectCoordinateSystem(this.points[1].position,B);
			this.getPointInProjectCoordinateSystem(this.points[2].position,C);
			const center = Potree.Utils.computeCircleCenter(A, B, C);
			return center.distanceTo(A);
		}
		return 0;
	}

	createSphereMaterial () {
		const sphereMaterial = new THREE.MeshBasicMaterial({
			//shading: THREE.SmoothShading,
			color: new THREE.Color(1,1,1),
			transparent: true,
			opacity: 0.5,
			depthTest: false,
			depthWrite: false}
		);

		return sphereMaterial;
	}

	addMarker (point) {
		if (point.x != null) {
			point = {position: point};
		}else if(point instanceof Array){
			point = {position: new THREE.Vector3(...point)};
		}
		this.points.push(point);

		// sphere
		const sphere = new THREE.Mesh(this.sphereGeometry, this.createSphereMaterial());

		//When we are representing a point we alter a first sphere
		//It is a normal sphere and for hoover and selected sphere we add another sphere to it
		if(this.maxMarkers == 1){
			
			const mat = new THREE.MeshBasicMaterial();
			mat.depthTest = false;
			mat.color = this.color;
			sphere.material = mat
			
			this.selectedSphere = new THREE.Mesh(new THREE.SphereGeometry(1.5, 10, 10), this.createSphereMaterial());
			this.selectedSphere.visible = false;
			sphere.add(this.selectedSphere);
		}

		this.add(sphere);
		this.spheres.push(sphere);

		{ // edges
			const lineGeometry = new LineGeometry();
			lineGeometry.setPositions( [
					0, 0, 0,
					0, 0, 0,
			]);

			const lineMaterial = new LineMaterial({
				color: 0xff0000, 
				linewidth: 2, 
				resolution:  new THREE.Vector2(1000, 1000),
			});

			lineMaterial.depthTest = false;

			const edge = new Line2(lineGeometry, lineMaterial);
			edge.visible = true;

			this.add(edge);
			this.edges.push(edge);
		}

		{ // edge labels
			const edgeLabel = new TextSprite();
			edgeLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
			edgeLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
			edgeLabel.material.depthTest = false;
			edgeLabel.visible = false;
			edgeLabel.fontsize = 16;
			this.edgeLabels.push(edgeLabel);
			this.add(edgeLabel);
		}

		{ // coordinate labels
			const coordinateLabel = new TextSprite();
			coordinateLabel.setBorderColor({r: 0, g: 0, b: 0, a: 1.0});
			coordinateLabel.setBackgroundColor({r: 0, g: 0, b: 0, a: 1.0});
			coordinateLabel.fontsize = 16;
			coordinateLabel.material.depthTest = false;
			coordinateLabel.material.opacity = 1;
			coordinateLabel.visible = false;
			this.coordinateLabels.push(coordinateLabel);
			this.add(coordinateLabel);
		}

		this.setMarker(this.points.length - 1, point);
	}

	removeMarker (index) {
		this.points.splice(index, 1);

		this.remove(this.spheres[index]);

		const edgeIndex = (index === 0) ? 0 : (index - 1);
		this.remove(this.edges[edgeIndex]);
		this.edges.splice(edgeIndex, 1);

		this.remove(this.edgeLabels[edgeIndex]);
		this.edgeLabels.splice(edgeIndex, 1);
		this.coordinateLabels.splice(index, 1);

		this.spheres.splice(index, 1);

		this.update();

		this.dispatchEvent({type: "marker_removed", measurement: this});
	}

	setMarker (index, point) {
		this.points[index] = point;

		const event = {
			type: "marker_moved",
			measure:	this,
			index:	index,
			position: point.position.clone()
		};
		this.dispatchEvent(event);

		this.update();
	}

	setPosition (index, position) {
		const point = this.points[index];
		point.position.copy(position);

		const event = {
			type: "marker_moved",
			measure:	this,
			index:	index,
			position: position.clone()
		};
		this.dispatchEvent(event);

		this.update();
	}

	getArea2D () {
		let area = 0;
		let j = this.points.length - 1;
		const outVector1 = new Vector3();
		const outVector2 = new Vector3();
		for (let i = 0; i < this.points.length; i++) {
			const p1 = this.getPointInProjectCoordinateSystem(this.points[i].position, outVector1);
			const p2 = this.getPointInProjectCoordinateSystem(this.points[j].position, outVector2) ;
			area += (p2.x + p1.x) * (p1.y - p2.y);
			j = i;
		}

		return Math.abs(area / 2);
	}

	getTotalDistance () {
		if (this.points.length === 0) {
			return 0;
		}

		let distance = 0;
		const outVector1 = new Vector3();
		const outVector2 = new Vector3();

		for (let i = 1; i < this.points.length; i++) {
			const prev = this.getPointInProjectCoordinateSystem(this.points[i - 1].position, outVector1);
			const curr = this.getPointInProjectCoordinateSystem(this.points[i].position, outVector2);
			const d = prev.distanceTo(curr);

			distance += d;
		}

		if (this.closed && this.points.length > 1) {
			const first = this.getPointInProjectCoordinateSystem(this.points[0].position,outVector1);
			const last = this.getPointInProjectCoordinateSystem(this.points[this.points.length - 1].position,outVector2);
			const d = last.distanceTo(first);

			distance += d;
		}

		return distance;
	}

	getAngleBetweenLines (cornerPoint, point1, point2) {
		const v1 = new THREE.Vector3().subVectors(point1.position, cornerPoint.position);
		const v2 = new THREE.Vector3().subVectors(point2.position, cornerPoint.position);

		// avoid the error printed by threejs if denominator is 0
		const denominator = Math.sqrt( v1.lengthSq() * v2.lengthSq() );
		if(denominator === 0){
			return 0;
		}else{
			return v1.angleTo(v2);
		}
	}

	getHorizontalAngle (point1: Vector3, point2: Vector3) {
		const v1 = new THREE.Vector3().subVectors(point2, point1).normalize();
		const v2 = new THREE.Vector3().set(v1.x,v1.y, 0).normalize();

		// avoid the error printed by threejs if denominator is 0
		const denominator = Math.sqrt( v1.lengthSq() * v2.lengthSq() );
		if(denominator === 0){
			return 0;
		}else{
			return v2.angleTo(v1);
		}
	}

	update () {
		if (this.points.length === 0) {
			return;
		} else if (this.points.length === 1) {
			const point = this.points[0];
			const position = point.position;
			this.spheres[0].position.copy(position);

			{ // coordinate labels
				const coordinateLabel = this.coordinateLabels[0];

				const pos = this.getPointInProjectCoordinateSystem(position,tempVector);
				
				const msg = `${Utils.formatUnits(pos.x,this.unit)}  ${Utils.formatUnits(pos.y,this.unit)}  ${Utils.formatUnits(pos.z,this.unit)}`
				coordinateLabel.setText(msg);

				coordinateLabel.visible = this.showCoordinates;
			}

			return;
		}

		const lastIndex = this.points.length - 1;

		const centroid = new THREE.Vector3();
		for (let i = 0; i <= lastIndex; i++) {
			const point = this.points[i];
			centroid.add(point.position);
		}
		centroid.divideScalar(this.points.length);

		for (let i = 0; i <= lastIndex; i++) {
			const index = i;
			const nextIndex = (i + 1 > lastIndex) ? 0 : i + 1;

			const point = this.points[index];
			const nextPoint = this.points[nextIndex];

			const sphere = this.spheres[index];

			// spheres
			sphere.position.copy(point.position);

			{ // edges
				const edge = this.edges[index];

				edge.material.color = this.color;

				edge.position.copy(point.position);

				edge.geometry.setPositions([
					0, 0, 0,
					...nextPoint.position.clone().sub(point.position).toArray(),
				]);

				edge.geometry.verticesNeedUpdate = true;
				edge.geometry.computeBoundingSphere();
				edge.computeLineDistances();
				edge.visible = index < lastIndex || this.closed;
				
				if(!this.showEdges){
					edge.visible = false;
				}
			}

			{ // edge labels
				const edgeLabel = this.edgeLabels[i];

				let center = new THREE.Vector3().add(point.position);
				center.add(nextPoint.position);
				center = center.multiplyScalar(0.5);
				
				edgeLabel.position.copy(center);
				
				const texts = [];

				if(this._measurementTypes.includes(MeasurementType.Distance2D)){
					let distance2D: number | null = null;
					// When measurement is displayed on map, check if we have targetCoodrdinateSystem
					// and if we do use proj4 to convert coordinates between map coordinate system and project coordinate system.
					// proj4 is used because Utils.distanceOnMap2d() might not calculate exact measurements, because
					// the function converts map coordinates to latLong and then measures distance in latlong which might not be 
					// the most precise method.
					if(this.hasMapCoordinates && this.projectCoordinateSystem){
						const p1 = proj4(this.mapCoordinateSystem, this.projectCoordinateSystem).forward([point.position.x, point.position.y, 0]);
						const p2 = proj4(this.mapCoordinateSystem, this.projectCoordinateSystem).forward([nextPoint.position.x, nextPoint.position.y, 0]);
						const v1 = new THREE.Vector3(p1[0],p1[1],0);
						const v2 = new THREE.Vector3(p2[0],p2[1],0);
						distance2D =  v1.distanceTo(v2);
					}else if(this.hasMapCoordinates){
						distance2D = Utils.distanceOnMap2d(point.position,nextPoint.position);
					}else{
						distance2D = Utils.distance2d(point.position,nextPoint.position);
					}

					if(distance2D){
						texts.push(`2D=${Utils.formatUnits(distance2D,this.unit)}`);
					}
				}
				
				if(this._measurementTypes.includes(MeasurementType.Distance3D)){
					let distance3D: number | null = null;
					if (this.hasMapCoordinates && this.projectCoordinateSystem){
						if(this.hasMapCoordinates && this.projectCoordinateSystem){
							const p1 = proj4(this.mapCoordinateSystem, this.projectCoordinateSystem).forward([point.position.x, point.position.y, point.position.z]);
							const p2 = proj4(this.mapCoordinateSystem, this.projectCoordinateSystem).forward([nextPoint.position.x, nextPoint.position.y, nextPoint.position.z]);
							const v1 = new THREE.Vector3(p1[0],p1[1],p1[2]);
							const v2 = new THREE.Vector3(p2[0],p2[1],p2[2]);
							distance3D =  v1.distanceTo(v2);
						}
					}else if(this.hasMapCoordinates){
						distance3D = Utils.distanceOnMap3d(point.position,nextPoint.position);
					}else{
						distance3D = Utils.distance3d(point.position,nextPoint.position);
					}

					if(distance3D){
						texts.push(`3D=${Utils.formatUnits(distance3D,this.unit)}`);
					}
				}

				if(this.showHeight){
					const height = Math.abs(nextPoint.position.z - point.position.z);
					texts.push(`h=${Utils.formatUnits(height,this.unit)}`);
				}

				if(this.showAngles && (index < lastIndex) && this.points.length >= 2){
					const angle = this.getHorizontalAngle(point.position, nextPoint.position);
					texts.push(`\u2220=${radToDeg(angle).toFixed(1)}\u00B0`);
				}

				edgeLabel.setText(texts.join("  "));
				edgeLabel.visible = texts.length > 0 && (index < lastIndex || this.closed);
			}
		}

		{ // update circle stuff
			const circleRadiusLabel = this.circleRadiusLabel;
			const circleRadiusLine = this.circleRadiusLine;
			const circleLine = this.circleLine;
			const circleCenter = this.circleCenter;

			const circleOkay = this.points.length === 3;

			circleRadiusLabel.visible = this.showCircle && circleOkay;
			circleRadiusLine.visible = this.showCircle && circleOkay;
			circleLine.visible = this.showCircle && circleOkay;
			circleCenter.visible = this.showCircle && circleOkay;

			if(this.showCircle && circleOkay){

				const A = this.points[0].position;
				const B = this.points[1].position;
				const C = this.points[2].position;
				const AB = B.clone().sub(A);
				const AC = C.clone().sub(A);
				const N = AC.clone().cross(AB).normalize();

				const center = Potree.Utils.computeCircleCenter(A, B, C);
				const radius = center.distanceTo(A);


				const scale = radius / 20;
				circleCenter.position.copy(center);
				circleCenter.scale.set(scale, scale, scale);

				//circleRadiusLine.geometry.vertices[0].set(0, 0, 0);
				//circleRadiusLine.geometry.vertices[1].copy(B.clone().sub(center));

				circleRadiusLine.geometry.setPositions( [
					0, 0, 0,
					...B.clone().sub(center).toArray()
				] );

				(circleRadiusLine.geometry as any).verticesNeedUpdate = true;
				circleRadiusLine.geometry.computeBoundingSphere();
				circleRadiusLine.position.copy(center);
				circleRadiusLine.computeLineDistances();

				const target = center.clone().add(N);
				circleLine.position.copy(center);
				circleLine.scale.set(radius, radius, radius);
				circleLine.lookAt(target);
				
				circleRadiusLabel.visible = true;
				circleRadiusLabel.position.copy(center.clone().add(B).multiplyScalar(0.5));
				circleRadiusLabel.setText(`${Utils.formatUnits(this.getRadius(), this.unit)}`);

			}
		}

		this.areaLabel.visible = this.showArea && this.points.length >= 3;
		this.updateAreaMesh();
		if(this.areaLabel.visible){ // update area label
			this.areaLabel.position.copy(centroid);

			const areaTexts = [];
			if(this._measurementTypes.includes(MeasurementType.Area2D)){
				areaTexts.push(`\u00872D=${Utils.formatUnits(this.getArea2D(), this.unit+"\u00B2")}`);
			}
			if(this._measurementTypes.includes(MeasurementType.Area3D)){
				areaTexts.push(`\u00873D=${Utils.formatUnits(this.areaMesh3dMeasurement,this.unit + "\u00B2")}`);
			}
			this.areaLabel.setText(areaTexts.join("  "));
		}

		//CUSTOM
	
		//Show circle line if set
		if (this.showCircle) {
			this.circleLine.material.color = this.color;
			this.circleRadiusLine.material.color = this.color;
		}
	
		//Show points for editing
		if (this.showCoordinates) {
			for (const sphere of this.spheres) {
				sphere.material.color = this.color;
			}
		}
	}

	raycast (raycaster, intersects) {
		for (let i = 0; i < this.points.length; i++) {
			const sphere = this.spheres[i];

			sphere.raycast(raycaster, intersects);
		}

		// recalculate distances because they are not necessarely correct
		// for scaled objects.
		// see https://github.com/mrdoob/three.js/issues/5827
		// TODO: remove this once the bug has been fixed
		for (let i = 0; i < intersects.length; i++) {
			const I = intersects[i];
			I.distance = raycaster.ray.origin.distanceTo(I.point);
		}
		intersects.sort(function (a, b) { return a.distance - b.distance; });
	}

	get showCoordinates () {
		return this._showCoordinates;
	}

	set showCoordinates (value) {
		this._showCoordinates = value;
		this.update();
	}

	get showAngles () {
		return this._showAngles;
	}

	set showAngles (value) {
		this._showAngles = value;
		this.update();
	}

	get showCircle () {
		return this._showCircle;
	}

	set showCircle (value) {
		this._showCircle = value;
		this.update();
	}

	get showAzimuth(){
		return this._showAzimuth;
	}

	set showAzimuth(value){
		this._showAzimuth = value;
		this.update();
	}

	get showEdges () {
		return this._showEdges;
	}

	set showEdges (value) {
		this._showEdges = value;
		this.update();
	}

	get showHeight () {
		return this._showHeight;
	}

	set showHeight (value) {
		this._showHeight = value;
		this.update();
	}

	get showArea () {
		return this._showArea;
	}

	set showArea (value) {
		this._showArea = value;
		this.update();
	}

	get closed () {
		return this._closed;
	}

	set closed (value) {
		this._closed = value;
		this.update();
	}

	get showDistances () {
		return this._showDistances;
	}

	set showDistances (value) {
		this._showDistances = value;
		this.update();
	}
}
