import { MapProvider, UnitsUtils } from "geo-three";
import * as THREE from "three";
import { Utils } from "../utils/utils";

export class OrthophotoMapProvider extends MapProvider {
	transparentImage: any;
	bounds: number[] = [];
	minZoom = 0;
	maxZoom = 20;
	debug = false;
	baseUrl: string;

	constructor(baseUrl: string) {
		super();
		this.baseUrl = baseUrl;
		(this as any).resolution = 256;
		this.transparentImage = document.createElement("canvas");
		this.transparentImage.width = 1;
		this.transparentImage.height = 1;
		this.transparentImage.getContext("2d").clearRect(0, 0, 1, 1);
	}

	getMetadata() {
		return new Promise((resolve) => {
			const d = this as any;
			resolve({
				name: d.name,
				minZoom: d.minZoom,
				maxZoom: d.maxZoom,
				bounds: d.bounds,
				// center : meta.center;
			});
		});
	}

	isInBounds(zoom: number, x: number, y: number) {
		if (zoom < this.minZoom || zoom > this.maxZoom) {
			return false;
		}
		const latLong = UnitsUtils.quadtreeToDatums(zoom, x, y);
		const latLong2 = UnitsUtils.quadtreeToDatums(zoom, x + 1, y + 1);

		//TODO improve performance for this min max calculations
		const p1l = Math.min(latLong.longitude, latLong2.longitude);
		const p1r = Math.max(latLong.longitude, latLong2.longitude);
		const p1t = Math.max(latLong.latitude, latLong2.latitude);
		const p1b = Math.min(latLong.latitude, latLong2.latitude);

		const p2l = Math.min(this.bounds[0], this.bounds[2]);
		const p2r = Math.max(this.bounds[0], this.bounds[2]);
		const p2t = Math.max(this.bounds[1], this.bounds[3]);
		const p2b = Math.min(this.bounds[1], this.bounds[3]);

		// let lat = latLong.latitude;
		// let long = latLong.longitude;
		// let intersects = this.rectanglesIntersect(latLong.longitude, latLong.latitude, latLong2.longitude, latLong2.latitude,this.bounds[0],this.bounds[3],this.bounds[2],this.bounds[1]);
		const intersects = Utils.rectanglesIntersect(p1l, p1t, p1r, p1b, p2l, p2t, p2r, p2b);
		return intersects;
	}

	fetchTile(zoom, x, y) {
		if (!this.debug) {
			return new Promise((resolve) => {
				if (!this.isInBounds(zoom, x, y)) {
					resolve(this.transparentImage);
					return;
				}
				const image = document.createElement("img");
				image.crossOrigin = "anonymous";
				image.onload = () => {
					// console.log("Image loaded:", image.src);
					resolve(image);
				};
				//Convert coodinates from xyz to tms that is generated by GDAL 2 tiles
				//https://gist.github.com/tmcw/4954720
				y = Math.pow(2, zoom) - y - 1;
				image.onerror = (e) => {
					console.log("Image load error", e);
					resolve(this.transparentImage);
				};
				image.src = `${this.baseUrl}/${zoom}/${x}/${y}.png`;
			});
		} else {
			const canvas = document.createElement("canvas");
			canvas.width = (this as any).resolution;
			canvas.height = (this as any).resolution;
			if (!this.isInBounds(zoom, x, y)) {
				return Promise.resolve(this.transparentImage);
			}
			const context = canvas.getContext("2d");
			if (context) {
				const green = new THREE.Color(0x00ff00);
				const red = new THREE.Color(0xff0000);
				const color = green.lerpHSL(red, (zoom - (this as any).minZoom) / ((this as any).maxZoom - (this as any).minZoom));
				context.fillStyle = color.getStyle();
				context.fillRect(0, 0, (this as any).resolution, (this as any).resolution);
				context.fillStyle = "#000000";
				context.textAlign = "center";
				context.textBaseline = "middle";
				context.font = "bold " + (this as any).resolution * 0.1 + "px arial";
				context.fillText("(" + zoom + ")", (this as any).resolution / 2, (this as any).resolution * 0.4);
				context.fillText("(" + x + ", " + y + ")", (this as any).resolution / 2, (this as any).resolution * 0.6);
				return Promise.resolve(canvas);
			}
			return null;
		}
	}
}
