<template>
	<div class="mapboxgl-ctrl-bottom-right">
		<div class="mapboxgl-ctrl mapboxgl-ctrl-attrib">
			<button class="mapboxgl-ctrl-attrib-button" type="button" aria-label="Toggle attribution"><span
					class="mapboxgl-ctrl-icon" aria-hidden="true" title="Toggle attribution"></span>
			</button>
			<div class="mapboxgl-ctrl-attrib-inner" role="list">
				<a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox" aria-label="Mapbox"
					role="listitem">© Mapbox</a>
				<a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap"
					aria-label="OpenStreetMap" role="listitem">© OpenStreetMap</a>
				<a class="mapbox-improve-map" href="https://apps.mapbox.com/feedback/" target="_blank"
					aria-label="Map feedback" role="listitem" rel="noopener nofollow">Improve this
					map</a>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.mapboxgl-ctrl-bottom-right {
	.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
		background-color: transparent;

		a {
			color: $text-color;

			&:hover {
				color: $text-color-selected;
			}
		}
	}
}
</style>
