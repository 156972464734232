import { TextureCache } from "./texture-cache";
import * as THREE from "three";
import SpriteText from "three-spritetext";
import { ElementType } from "../data-structures/element-type";
import { SceneElement } from "../data-structures/scene-element";

export class Annotation extends THREE.Object3D implements SceneElement {
	private label: any;
	private descriptionLabel: any;
	private markerSprite: THREE.Sprite;
	private isSelected = false;
	private isHovered = false;
	private isPerspectiveScale = true;
	private normalScale: THREE.Vector3 = new THREE.Vector3(
		0.05 * (33 / 41),
		0.05,
		0.05
	);
	private highlightedScale: THREE.Vector3 = new THREE.Vector3(
		0.06 * (33 / 41),
		0.06,
		0.06
	);
	// private normalOrthographicScale: THREE.Vector3 = new THREE.Vector3((33/41)*5, 5, 5)
	// private highlightedOrthographicScale: THREE.Vector3 = new THREE.Vector3((33/41)*5.1, 5.1, 5.1);

	private attentuationScale = 0.00007;

	public circleCenter: THREE.Mesh;

	public get usePerspectiveScale() {
		return this.isPerspectiveScale;
	}

	public set usePerspectiveScale(value) {
		this.isPerspectiveScale = value;
		this.updateScales();
	}

	public get elementType(): ElementType {
		return "annotation";
	}

	public get canMove(): boolean {
		return this.isSelected;
	}

	public get title(): string {
		return this.label.text;
	}

	public set title(value: string) {
		this.label.text = value;
		this.label.scale.multiplyScalar(
			this.attentuationScale * this.label.fontSize
		);
	}

	public get color(): any {
		return this.markerSprite.material.color;
	}

	public set color(color: any) {
		this.markerSprite.material.color.set(color);
	}

	public get description(): string {
		return this.descriptionLabel.text;
	}

	public set description(value: string) {
		this.descriptionLabel.text = value;
		this.descriptionLabel.scale.multiplyScalar(
			this.attentuationScale * this.descriptionLabel.fontSize
		);
		const newLines = this.descriptionLabel.text.split("\n").length;
		const prefferedOffset = -6;
		const offset =
			newLines != 0 ? prefferedOffset / newLines : prefferedOffset;
		console.log("Offset:", offset);
		this.descriptionLabel.center.set(0.5, offset);
		this.updateDescriptionVisibility();
	}

	get hover(): boolean {
		return this.isHovered;
	}

	set hover(value: boolean) {
		this.isHovered = value;
		this.updateScales();
		this.updateDescriptionVisibility();
	}

	get selected(): boolean {
		return this.isSelected;
	}

	set selected(value: boolean) {
		this.isSelected = value;
		// this.markerSprite.material.map = this.isSelected ? this.selectedTexture : this.normalTexture;
		this.updateScales();
		this.updateDescriptionVisibility();
	}

	constructor() {
		super();

		//Label
		this.label = new SpriteText("New Annotation");
		this.label.color = "#FFFFFF";
		this.label.backgroundColor = "#000000";
		this.label.fontSize = 24;
		this.label.material.depthTest = false;
		this.label.material.depthWrite = false;
		this.label.material.sizeAttenuation = false;
		this.label.borderRadius = 4;
		this.label.padding = [8, 3];
		/* Scaling the label. */
		this.label.scale.multiplyScalar(this.attentuationScale);
		this.label.center.set(0.5, -2.5);
		this.title = "New Annotation";
		this.add(this.label);

		//Description label
		this.descriptionLabel = new SpriteText("");

		this.descriptionLabel.color = "#FFFFFF";
		this.descriptionLabel.backgroundColor = "rgba(40,40,40,0.5)";
		this.descriptionLabel.fontSize = 20;
		this.descriptionLabel.borderRadius = 4;
		this.descriptionLabel.padding = [8, 3];
		this.descriptionLabel.material.depthWrite = false;
		this.descriptionLabel.material.depthTest = false;
		this.descriptionLabel.material.sizeAttenuation = false;
		this.descriptionLabel.scale.multiplyScalar(this.attentuationScale);
		this.descriptionLabel.center.set(0.5, -3);
		this.descriptionLabel.visible = false;
		this.description = "";
		this.add(this.descriptionLabel);

		//Marker sprite
		const texture = new TextureCache().load("/images/marker.png");
		texture.minFilter = THREE.LinearFilter;
		texture.magFilter = THREE.LinearFilter;

		const spriteMaterial = new THREE.SpriteMaterial({
			map: texture,
			depthTest: false,
			depthWrite: false,
			sizeAttenuation: false,
		});

		const sprite = new THREE.Sprite(spriteMaterial);
		const aspect = 33 / 41.0;
		sprite.center.set(0.5, 0);
		sprite.scale.multiply(new THREE.Vector3(0.05 * aspect, 0.05, 0.05));
		this.markerSprite = sprite;

		//Circle selection point
		const sg = new THREE.CircleGeometry(0.7, 12);
		const sm = new THREE.MeshBasicMaterial();
		sm.transparent = true;
		sm.opacity = 0.5;
		sm.side = THREE.DoubleSide;
		sm.depthTest = false;
		
		this.circleCenter = new THREE.Mesh(sg, sm);
		// this.circleCenter.rotation.set(Math.PI*0.5,0,0);
		this.circleCenter.visible = false;
		this.add(this.circleCenter);

		this.add(sprite);
	}

	public getType() {
		return "annotation";
	}

	private updateScales() {
		const scale = this.isSelected
			? this.highlightedScale
			: this.normalScale;
		
		this.circleCenter.visible = this.isSelected;
		this.markerSprite.scale.set(scale.x, scale.y, scale.z);
	}

	private updateDescriptionVisibility() {
		this.descriptionLabel.visible =
			this.descriptionLabel.text.length > 0 &&
			(this.isHovered || this.isSelected);
	}
}
