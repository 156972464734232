import { Utils } from "@/classes/utils/utils";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useNotificationStore = defineStore("notifications", () => {
	const notifications = reactive<string[]>([]);

	async function addNotification(message: string, duration = 3000) {
		notifications.push(message);
		await Utils.delay(duration);
		const index = notifications.indexOf(message);
		if(index >= 0){
			notifications.splice(index,1);
		}
	}

	return { 
		notifications,
		addNotification
	};
});
