<template>
	<div class="dashboard-sidebar">
		<h2 class="fw-bold">Cloud Viewer</h2>
		
		<ul class="nav flex-column">
			<li class="nav-item">
				<router-link :to="{ name: 'dashboard' }" class="nav-link">
					Dashboard
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{ name: 'users' }" class="nav-link">
					Users
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{ name: 'projects-and-storage' }" class="nav-link">
					Projects and storage
				</router-link>
			</li>
			<li class="nav-item">
				<a href="/monitor/queues/queue/data-processor" class="nav-link" aria-disabled="true">Jobs</a>
			</li>
		</ul>
	</div>
</template>
<script setup lang="ts">

</script>
<style lang="scss">
.dashboard-sidebar {
	background-color: rgba(0, 0, 0, 0.2);
	min-width: 250px;
	padding: 20px;
	overflow-y: auto;

	.nav-link{
		color: $text-color;
		font-size: 14px;

		&:hover, &:active, &.router-link-active{
			color: $text-color-selected;
		}
	}
}
</style>
