/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProjectInput
 */
export interface UpdateProjectInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInput
     */
    projectUUID: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInput
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateProjectInput
     */
    latLong?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectInput
     */
    coordinateSystemId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectInput
     */
    sharing?: UpdateProjectInputSharingEnum;
}


/**
 * @export
 */
export const UpdateProjectInputSharingEnum = {
    None: 'none',
    AnyoneCanView: 'anyone_can_view'
} as const;
export type UpdateProjectInputSharingEnum = typeof UpdateProjectInputSharingEnum[keyof typeof UpdateProjectInputSharingEnum];


/**
 * Check if a given object implements the UpdateProjectInput interface.
 */
export function instanceOfUpdateProjectInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "projectUUID" in value;

    return isInstance;
}

export function UpdateProjectInputFromJSON(json: any): UpdateProjectInput {
    return UpdateProjectInputFromJSONTyped(json, false);
}

export function UpdateProjectInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProjectInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectUUID': json['projectUUID'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'latLong': !exists(json, 'latLong') ? undefined : json['latLong'],
        'coordinateSystemId': !exists(json, 'coordinateSystemId') ? undefined : json['coordinateSystemId'],
        'sharing': !exists(json, 'sharing') ? undefined : json['sharing'],
    };
}

export function UpdateProjectInputToJSON(value?: UpdateProjectInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectUUID': value.projectUUID,
        'name': value.name,
        'latLong': value.latLong,
        'coordinateSystemId': value.coordinateSystemId,
        'sharing': value.sharing,
    };
}

