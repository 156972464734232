/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Survey } from './Survey';
import {
    SurveyFromJSON,
    SurveyFromJSONTyped,
    SurveyToJSON,
} from './Survey';

/**
 * 
 * @export
 * @interface SurveyInputData
 */
export interface SurveyInputData {
    /**
     * 
     * @type {string}
     * @memberof SurveyInputData
     */
    uuid: string;
    /**
     * 
     * @type {Survey}
     * @memberof SurveyInputData
     */
    survey: Survey;
    /**
     * 
     * @type {Date}
     * @memberof SurveyInputData
     */
    uploadDate: Date;
    /**
     * 
     * @type {string}
     * @memberof SurveyInputData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyInputData
     */
    type: SurveyInputDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SurveyInputData
     */
    status: SurveyInputDataStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SurveyInputData
     */
    requiredFilesForProcessing: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveyInputData
     */
    originalFileUrls: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof SurveyInputData
     */
    originalFileSize: object;
    /**
     * 
     * @type {string}
     * @memberof SurveyInputData
     */
    uploadOrigin: string;
}


/**
 * @export
 */
export const SurveyInputDataTypeEnum = {
    Orthophoto: 'orthophoto',
    Model: 'model',
    Pointcloud: 'pointcloud',
    Cad: 'cad',
    Video: 'video',
    Unknown: 'unknown'
} as const;
export type SurveyInputDataTypeEnum = typeof SurveyInputDataTypeEnum[keyof typeof SurveyInputDataTypeEnum];

/**
 * @export
 */
export const SurveyInputDataStatusEnum = {
    Uploading: 'uploading',
    Waitingforprocessing: 'waitingforprocessing',
    Processing: 'processing',
    Processingfailed: 'processingfailed',
    Processed: 'processed'
} as const;
export type SurveyInputDataStatusEnum = typeof SurveyInputDataStatusEnum[keyof typeof SurveyInputDataStatusEnum];


/**
 * Check if a given object implements the SurveyInputData interface.
 */
export function instanceOfSurveyInputData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "survey" in value;
    isInstance = isInstance && "uploadDate" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "requiredFilesForProcessing" in value;
    isInstance = isInstance && "originalFileUrls" in value;
    isInstance = isInstance && "originalFileSize" in value;
    isInstance = isInstance && "uploadOrigin" in value;

    return isInstance;
}

export function SurveyInputDataFromJSON(json: any): SurveyInputData {
    return SurveyInputDataFromJSONTyped(json, false);
}

export function SurveyInputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyInputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': json['uuid'],
        'survey': SurveyFromJSON(json['survey']),
        'uploadDate': (new Date(json['uploadDate'])),
        'name': json['name'],
        'type': json['type'],
        'status': json['status'],
        'requiredFilesForProcessing': json['requiredFilesForProcessing'],
        'originalFileUrls': json['originalFileUrls'],
        'originalFileSize': json['originalFileSize'],
        'uploadOrigin': json['uploadOrigin'],
    };
}

export function SurveyInputDataToJSON(value?: SurveyInputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'survey': SurveyToJSON(value.survey),
        'uploadDate': (value.uploadDate.toISOString()),
        'name': value.name,
        'type': value.type,
        'status': value.status,
        'requiredFilesForProcessing': value.requiredFilesForProcessing,
        'originalFileUrls': value.originalFileUrls,
        'originalFileSize': value.originalFileSize,
        'uploadOrigin': value.uploadOrigin,
    };
}

