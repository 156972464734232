/* tslint:disable */
/* eslint-disable */
/**
 * 3DSurvey API
 * Frontend API for 3d Survey
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserQueryInput
 */
export interface UserQueryInput {
    /**
     * 
     * @type {string}
     * @memberof UserQueryInput
     */
    searchString: string;
    /**
     * 
     * @type {number}
     * @memberof UserQueryInput
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof UserQueryInput
     */
    limit: number;
    /**
     * 
     * @type {string}
     * @memberof UserQueryInput
     */
    sortProperty: UserQueryInputSortPropertyEnum;
    /**
     * 
     * @type {string}
     * @memberof UserQueryInput
     */
    sortOrder: UserQueryInputSortOrderEnum;
}


/**
 * @export
 */
export const UserQueryInputSortPropertyEnum = {
    CreatedDate: 'createdDate',
    UsedStorage: 'usedStorage'
} as const;
export type UserQueryInputSortPropertyEnum = typeof UserQueryInputSortPropertyEnum[keyof typeof UserQueryInputSortPropertyEnum];

/**
 * @export
 */
export const UserQueryInputSortOrderEnum = {
    Empty: '',
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type UserQueryInputSortOrderEnum = typeof UserQueryInputSortOrderEnum[keyof typeof UserQueryInputSortOrderEnum];


/**
 * Check if a given object implements the UserQueryInput interface.
 */
export function instanceOfUserQueryInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchString" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "sortProperty" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function UserQueryInputFromJSON(json: any): UserQueryInput {
    return UserQueryInputFromJSONTyped(json, false);
}

export function UserQueryInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserQueryInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchString': json['searchString'],
        'offset': json['offset'],
        'limit': json['limit'],
        'sortProperty': json['sortProperty'],
        'sortOrder': json['sortOrder'],
    };
}

export function UserQueryInputToJSON(value?: UserQueryInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchString': value.searchString,
        'offset': value.offset,
        'limit': value.limit,
        'sortProperty': value.sortProperty,
        'sortOrder': value.sortOrder,
    };
}

