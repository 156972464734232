import { LODRadial } from "geo-three";
import * as THREE from "three";
import { MathUtils } from "three";
import { UnitsUtils } from "geo-three";
import { Utils } from "../utils/utils";

export class LODCameraDistance extends LODRadial {
	projection = new THREE.Matrix4();
	pov = new THREE.Vector3();
	plane = new THREE.Plane();
	frustum = new THREE.Frustum();
	position = new THREE.Vector3();
	subdivideDistance = 30;
	simplifyDistance = 800;
	testCenter = true;
	pointOnly = false;
	potreeViewer: any;

	constructor() {
		super();
		// this.potreeViewer = potreeViewer
	}

	isInBounds(zoom: number, x: number, y: number, bounds: number[]) {
		const latLong = UnitsUtils.quadtreeToDatums(zoom, x, y);
		const latLong2 = UnitsUtils.quadtreeToDatums(zoom, x + 1, y + 1);

		//TODO improve performance for this min max calculations
		const p1l = Math.min(latLong.longitude, latLong2.longitude);
		const p1r = Math.max(latLong.longitude, latLong2.longitude);
		const p1t = Math.max(latLong.latitude, latLong2.latitude);
		const p1b = Math.min(latLong.latitude, latLong2.latitude);

		const p2l = Math.min(bounds[0], bounds[2]);
		const p2r = Math.max(bounds[0], bounds[2]);
		const p2t = Math.max(bounds[1], bounds[3]);
		const p2b = Math.min(bounds[1], bounds[3]);

		// let lat = latLong.latitude;
		// let long = latLong.longitude;
		// let intersects = this.rectanglesIntersect(latLong.longitude, latLong.latitude, latLong2.longitude, latLong2.latitude,this.bounds[0],this.bounds[3],this.bounds[2],this.bounds[1]);
		const intersects = Utils.rectanglesIntersect(p1l, p1t, p1r, p1b, p2l, p2t, p2r, p2b);
		return intersects;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	updateLOD(view, camera, renderer, scene) {
		//Do not update lod if the object is hidden or if it is not in the scene tree
		if(view.parent == null || !view.visible){
			return;
		}

		this.projection.multiplyMatrices(
			camera.projectionMatrix,
			camera.matrixWorldInverse
		);
		this.frustum.setFromProjectionMatrix(this.projection);
		camera.getWorldPosition(this.pov);

		//Determine how close to max zoom we are by comparing orthographic size to pixel size of full map
		const maxZoom = view.provider.maxZoom;
		const percentage = Math.pow(2, maxZoom) / 30000000; //Value between 20000000 and 40000000 which are half or full map bound

		const initialLevel = Math.log2(Math.max(camera.right, camera.top) * percentage)-0.5;
		//invert and clamp initial level
		const desiredLevel = MathUtils.clamp(Math.ceil(maxZoom - initialLevel), view.provider.minZoom,view.provider.maxZoom);
		
		// console.log("zoom level", desiredLevel, initialLevel);
		view.children[0].traverse((node) => {
			//Skip nodes that are outside of bounds of map provider. 
			//This is true for OrthophotoMapProvider where if this was set
			//then the map would be by default set to minLevel of orthophoto and when 
			//zooming out the map would be subdivider heavily, causing performance issues.
			//This basically allows you to show map at min zoom level 16 without subdividing 
			//the whole map but only the nodes in bounds
			if(view.provider.bounds.length > 0 && !this.isInBounds(node.level,node.x,node.y, view.provider.bounds)){
				return;
			}
			node.getWorldPosition(this.position);

			const inFrustum = this.pointOnly
				? this.frustum.containsPoint(this.position)
				: this.frustum.intersectsObject(node);
			if (inFrustum && node.level < desiredLevel && !node.subdivided) {
				node.subdivide();
			} else if (node.parentNode && (inFrustum && node.level > desiredLevel)) {
				node.parentNode.simplify();
			}
			// else if (node.subdivided && node.parentNode && node.level > desiredLevel) {
			// 	node.parentNode.simplify();
			// }
		});
	}
}
