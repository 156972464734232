import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ViewPage from "../views/ViewPage.vue";
import PreviewPage from "../views/PreviewPage.vue";
import ComparePage from "../views/ComparePage.vue";
import ProjectsPage from "../views/ProjectsPage.vue";
import LoginCallbackPage from "../views/LoginCallbackPage.vue";
import EditProjectPage from "../views/EditProjectPage.vue";
import SignInPage from "../views/SignInPage.vue";
import DashboardPage from "../views/dashboard/DashboardPage.vue";
import UsersPage from "../views/dashboard/UsersPage.vue";
import ProjectsAndStoragePage from "../views/dashboard/ProjectsAndStoragePage.vue";

import { useUserStore } from "@/store/user-store";
const routes: Array<RouteRecordRaw> = [
	{
		path: "/view/:projectId/:surveyId?",
		name: "view",
		component: ViewPage,
	},
	{
		path: "/preview/:projectId/:surveyId?",
		name: "preview",
		component: PreviewPage,
	},
	{
		path: "/edit/:projectId?/:surveyId?",
		name: "edit",
		component: EditProjectPage,
	},
	{
		path: "/signin",
		name: "signin",
		component: SignInPage,
	},
	{
		path: "/compare/:projectId/:surveyId?/:surveyId2?",
		name: "compare",
		component: ComparePage,
	},
	{
		path: "/login",
		name: "login",
		component: LoginCallbackPage,
	},
	{
		path: "/login/callback",
		component: LoginCallbackPage,
	},
	{
		path: "/",
		name: "projects",
		component: ProjectsPage,
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: DashboardPage,
	},
	{
		path: "/dashboard/users",
		name: "users",
		component: UsersPage,
	},
	{
		path: "/dashboard/projects",
		name: "projects-and-storage",
		component: ProjectsAndStoragePage,
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

const unprotectedPaths = ["signin","login","view","native/select-project", "compare"];

router.beforeEach((to,from,next)=>{
	const userStore = useUserStore();

	// console.log("Router", from, to);
	
	let isPathUnprotected = false;
	for(const path of unprotectedPaths){
		isPathUnprotected = to.path.includes(path);
		if(isPathUnprotected){
			break
		}
	}
	if(to.path.startsWith("/dashboard") && !userStore.state.user?.admin){
		//Redirect non admin users to root
		next("/");
	}else if(isPathUnprotected || userStore.isLoggedIn()){
		next();
	}else{
		next("/signin")
	}
	
})

export default router;
